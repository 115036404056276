import * as React from "react"
import { SEO } from "../../components/seo"
import Layout from '../../components/vi/layout';
import { StaticImage } from "gatsby-plugin-image"
// import { Link } from "gatsby";

import heroBanner from '../../images/platform-hero.jpg';

const Platform = (props) => {
  return (
    <Layout pathname={props.location.pathname}>
      <section className="
      h-[600px]
      lg:h-[750px]
      bg-primary
      bg-no-repeat
      bg-top
      bg-cover
      relative"
      style={{ backgroundImage: `url(${heroBanner})`}}>
        <div className="absolute w-full lg:h-3/5 inset-X-0 bottom-0 pt-28 pb-20 lg:pt-10 lg:pb-20 z-10 bg-gradient-to-t from-primary px-4 lg:px-20 lg:flex lg:flex-col lg:justify-end text-center">
          <h1 className="animate__animated animate__fadeInDown animate__slower font-heading text-5xl lg:text-7xl text-white mb-2">Cách Chúng Ta Sẽ Thắng</h1>
          <p className="animate__animated animate__fadeInUp animate__slower text-xl font-light text-white drop-shadow-sm leading-relaxed">Kim Nguyễn-Penaloza đại diện cho thế hệ lãnh đạo tiếp nối của Hoa Kỳ.</p>
        </div>
      </section>
      <section className="py-28 px-10 lg:px-36">

        <div className="w-[800px] mx-auto max-w-full text-base leading-loose space-y-6">

          <p>Cô Kim Nguyễn-Penaloza thuộc thế hệ thiên niên kỷ, có lập trường ủng hộ quyền phá thai, và là người mang hai dòng máu di dân Mexico và người tị nạn Việt Nam, cô là khuôn mặt đại diện cho sự thay đổi chính trị ở California và Hoa Thịnh Đốn.</p>

          <p><strong>Địa Hạt CA-45 có nhiều triển vọng cho đảng Dân Chủ trong năm 2024!</strong></p>

          <p>Đây là địa hạt Tổng Thống Joe Biden thắng với hơn 6% và dân số cũng như tỷ lệ ghi danh cử tri trong địa hạt mang lại ưu thế cho đảng Dân Chủ.</p>

          <StaticImage src="../images/CA45-map.png" alt="Democrats 37.8%; Republicans 32.1%; NPP 24.4%;" placeholder="blurred" layout="fullWidth" className="mb-5" />

          <p>Cử tri gốc Châu Á Thái Bình Dương (AAPI) và gốc Latino chiếm đa số trong địa hạt ở mức 51.6%, trong đó, cử tri gốc Việt chiếm đa số trong khối cử tri gốc AAPI ở mức 58.4%.</p>

          <p>Địa Hạt CA-45 bao gồm Little Saigon của Quận Cam, nơi có cộng đồng người Việt lớn nhất trên thế giới ngoài Việt Nam, và gồm nhiều khu vực trong quận hạt Los Angeles và Quận Cam có số cư dân người Mỹ gốc Á đang ngày một phát triển.</p>

          <p>Nếu đắc cử, cô Kim sẽ trở thành người gốc Việt đầu tiên của California được bầu vào Quốc Hội Hoa Kỳ, và là người thứ ba trong lịch sử nước Mỹ. Cô cũng sẽ là phụ nữ gốc Châu Á và Latina đầu tiên trong Quốc Hội và là một thành viên trong nhóm thế hệ thiên niên kỷ ngày một đông với chủ trương mang đến thay đổi trong các vấn đề quan trọng nhất của chúng ta.</p>

          <p className="font-bold text-2xl mb-4 text-center text-primary">Hãy giúp chúng tôi tạo nên lịch sử và thay đổi địa hạt CA-45 thành màu xanh lam!</p>

          <p className="text-center">
            <a href="https://secure.actblue.com/donate/votekimnguyen"
              className="
              mt-10
              font-bold
              text-black
              bg-secondary
              text-lg
              rounded-full
              py-4
              px-8
              leading-none
              text-center
              transition-all
              duration-500
              hover:bg-secondary-dark
              ">
                Quyên Tặng
                <svg xmlns="http://www.w3.org/2000/svg" width="13.503" height="23.616" viewBox="0 0 13.503 23.616" role="img" aria-hidden="true" className="inline-block fill-[#3D3D3D] w-1.5 ml-2 align-middle -mt-1">
                  <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M20.679,18,11.742,9.07a1.681,1.681,0,0,1,0-2.384,1.7,1.7,0,0,1,2.391,0L24.258,16.8a1.685,1.685,0,0,1,.049,2.327L14.14,29.32a1.688,1.688,0,0,1-2.391-2.384Z" transform="translate(-11.246 -6.196)"/>
                </svg>
              </a>
          </p>

        </div>
      </section>
    </Layout>
  )
}

export default Platform

export const Head = ({location}) => <SEO title="Cách Chúng Ta Sẽ Thắng" pathname={location.pathname} />
